import React, { useState } from 'react';
import { DialogFE } from '../../Dialog';
import Table from '../../../elements/Table';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import {
  selectConsoleSet,
  selectLockingConsole,
  showDialog,
  storeSelectedNRWGConsoleSet,
} from '../../../redux/uiStateActions';
import { AnyAction } from 'redux';
import { LockingConsole } from '../../../redux/admin/adminFacadeReducer';

export function AddNRWGLockingConsoleSetDialog() {
  const suitableLockingConsoles = useSelector<IState, LockingConsole[]>(
    s => s.staticData.suitableLockingConsoles,
  );
  const [markedLockingConsole, setMarkedLockingConsole] = useState<
    LockingConsole | undefined
  >();
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function selectLockingConsoleSetHelper(): void {
    if (markedLockingConsole) {
      // dispatch(storeSelectedNRWGConsoleSet(markedLockingConsole));
      dispatch(selectLockingConsole(markedLockingConsole));
      dispatch(showDialog(undefined));
    }
  }

  return (
    <DialogFE
      heading="ADD_LOCKING_CONSOLE"
      componentClass="add-nrwg-console-set-dialog"
      footerProps={{
        cancelAction: true,
        saveAction: markedLockingConsole
          ? selectLockingConsoleSetHelper
          : undefined,
        primaryActionLabel: 'BUTTON_LABEL_ADD',
      }}
    >
      <Table
        head={{
          columns: [<FormattedMessage id="PDF_BRACKET_RESULT_LABLE" />],
        }}
        body={
          suitableLockingConsoles?.map(cs => {
            return {
              key: cs.name,
              selected: cs === markedLockingConsole,
              onClick: () => setMarkedLockingConsole(cs),
              columns: [<FormattedMessage id={cs.name} />],
            };
          }) || []
        }
      />
    </DialogFE>
  );
}
export default function AddNRWGConsoleSetDialog() {
  const nrwgConsoleSetCandidates = useSelector<IState, ConsoleSet[]>(
    s => s.staticData.nrwgConsoleSetCandidates,
  );
  const [markedConsoleSet, setMarkedConsoleSet] = useState<
    ConsoleSet | undefined
  >();
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function selectConsoleSetHelper(): void {
    if (markedConsoleSet) {
      dispatch(storeSelectedNRWGConsoleSet(markedConsoleSet));
      dispatch(selectConsoleSet([markedConsoleSet]));
      dispatch(showDialog(undefined));
    }
  }

  return (
    <DialogFE
      heading="ADD_CONSOLE"
      componentClass="add-nrwg-console-set-dialog"
      footerProps={{
        cancelAction: true,
        saveAction: markedConsoleSet ? selectConsoleSetHelper : undefined,
        primaryActionLabel: 'BUTTON_LABEL_ADD',
      }}
    >
      <Table
        head={{
          columns: [<FormattedMessage id="PDF_BRACKET_RESULT_LABLE" />],
        }}
        body={
          nrwgConsoleSetCandidates?.map(cs => {
            return {
              key: cs.name,
              selected: cs === markedConsoleSet,
              onClick: () => setMarkedConsoleSet(cs),
              columns: [<FormattedMessage id={cs.name} />],
            };
          }) || []
        }
      />
    </DialogFE>
  );
}
